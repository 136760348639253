.table-container {
    overflow-x: auto;
  }
  
  .custom-table {
    width: 100%;
    border-collapse: collapse;
    font-family: Arial, sans-serif;
    
    text-align: left;
  }
  
  .custom-table thead {
    background-color: #f8fafc;
  }
  
  .custom-table tr th {
    font-weight: bold;
    border-bottom: 1px solid #e5e7eb;
    text-align: left;
    padding: 12px;
  }
  
  .custom-table td {
    padding: 12px;
    border-bottom: 1px solid #e5e7eb;
    color: #374151;
  }
  
  .custom-table tbody tr:last-child td {
    border-bottom: none;
  }
  
  .custom-table tbody tr:hover {
    background-color: #f3f4f6;
    cursor: pointer;
  }
  
  .pagination_buttons {
    display: flex;
    gap: 5px;
  }

  .pagination_button {
    border-radius: 4px;
    color: #344054;
    background-color: #FFFFFF;
    font-family: Arial, sans-serif;
    font-size: 13px;
    border-color: #D0D5DD;
  }

  .pagination_button:disabled {
    cursor: not-allowed;
    color: gray;
    border-color: #fff;
  }

  .pagination {
    display: flex;
    justify-content: space-between;
  }

  .pagination_text {
    font-family: Arial, sans-serif;
    font-size: 13px;
  }