@media (max-width: 1199px) {
    #launcher-frame {
        min-width: 85px !important;
        max-width: 85px !important;
        top: 0px !important;
        right: 80px !important;
    }
    /* navbar header */
    .nav .navbar-inner
    {
        padding: 0 15px;
    }
    /* main page */
    .page-wrapper {
        max-height: 100%;
    }

    .content-inner {
        padding-left: 15px !important;
    }
    .content_wrap_comman
    {
        padding-left: 0;
        padding-right: 0;;
    }
    /* side bar menu */
    .content-inner aside
    {
        top: 52px;
        max-width: 240px;
        height: 100vh;
        z-index: 999;
    }
    .content-inner .sidebar-list {
        padding: 0 0 0 3px;
    }
    .sidebar-base .nav-item .nav-link {
        padding: 5px 0px 5px 10px !important;
    }

    .sidebar-base .nav-item .nav-link .item-name br {
        display: none;
        margin-right: 2px;
    }

    .sidebar-base .nav-item .nav-link .item-name {
        font-size: 12px !important;
        margin-left: 10px;
    }

    .sidebar-base .nav-item .nav-link:not(.disabled) .icon {
        width: 25px;
        height: 25px;
        min-width: 25px;
        margin: 0;
    }
    /*  */
    .content_wrap_comman
    {
        padding-top: 0;
    }
    /*  */
    .ai_assist_btn_div {
        position: fixed;
        bottom: 35px;
        right: 20px;
        z-index: 99;
    }

}
@media (max-width: 767px){
	.hide_mob{
		display: none !important;
	}
	
}
@media (min-width: 767px){
	.hide_desk{
		display: none !important;
	}
	
}

@media only screen and (min-width:320px) and (max-width:991px) {
    .new_crm_filter {
        justify-content: flex-start;
    }
    .manual_integration_area
    {
        margin-top: 15px;
    }
    .empty-element
    {
        padding: 15px 0;
    }
    .empty-element .img-block
    {
        max-width: 108px;
    }
    .agent_score_card h2
    {
        font-size: 18px;
    }
}

@media only screen and (min-width:320px) and (max-width:767px) {
    .no_bt_margin_sm
    {
        margin-bottom: 0 !important;
    }
    .page_title_box
    {
        margin-bottom: 15px;
    }
    /* calendar */
    .calendarWrap .rdrCalendarWrapper {
        width: 100% !important;  /* Full width on small screens */
        font-size: 12px !important;  /* Adjust font size */
    }
    .calendarWrap .rdrDateDisplay {
        margin: 6px 10px;
    }
    .calendarWrap .rdrDateDisplayItem input
    {
        height: 26px;
    }
    .calendarWrap .rdrMonthName{
        padding-bottom: 0;
    }
    .calendarWrap .rdrMonthAndYearWrapper {
        height: 40px;
        padding-top: 5px;
    }

    .calendarWrap .rdrDateRangeWrapper {
        flex-direction: column !important;  /* Stack calendars vertically */
    }

    .calendarWrap .rdrMonth {
        width: 100% !important; /* Ensure calendar fits */
        padding-bottom: 0 !important;
    }

    .calendarWrap .rdrDayNumber span {
        font-size: 12px !important; /* Adjust text inside date cells */
    }

    .calendarWrap .rdrInputRange {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .calendarWrap .rdrMonths{
        flex-wrap: wrap;
    }
    .calendarWrap .rdrDefinedRangesWrapper
    {
        width: 100%;
    }
    .calendarWrap .rdrCalendarWrapper
    {
        max-width: 100%;
    }
    .calendarWrap .rdrStaticRangeLabel
    {
        line-height: 14px;
        padding: 7px 5px;
    }
    .calendarWrap .rdrStaticRanges {
        display: inline-flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        display: none;
    }
    .calendarWrap .rdrInputRanges
    {
        display: none;
    }
    .calendarWrap .rdrDay
    {
        height: 30px !important;
    }
    .calendarWrap .rdrSelected, 
    .calendarWrap .rdrInRange, 
    .calendarWrap .rdrStartEdge, 
    .calendarWrap .rdrEndEdge
    {
        top: 3px;
        bottom: 3px;
    }
    .calendarWrap .rdrDateRangePickerWrapper
    {
        flex-wrap: wrap;
        width: 100%;
    }
    .calendarWrap .rdrWeekDays .rdrWeekDay:before
    {
        
        /* content: "";
        background-color: #ffffff;
        position: absolute;
        width: 18px;
        height: 100%;
        right: 0; */
    }
    .calendarWrap .rdrWeekDays .rdrWeekDay
    {
        /* position: relative;
        font-size: 12px;
        letter-spacing: 0.9px; */
    }
    /* fixed table */       
    .table-dashboard .rthfc.-se .-header .rt-th.rthfc-th-fixed,
    .table-dashboard .rthfc.-se .rt-td.rthfc-td-fixed
    {
        transform: none !important;
    }
    /*  */
    .radio_tab_area .nav-pills.flex-column
    {
        flex-direction: row !important;
    }
    .radio_tab_area .nav-pills.flex-column .nav-item .nav-link
    {
        font-size: 12px;
        padding: 5px 6px !important;
    }
    .table-dashboardSimple
    {
        max-width: 96vw !important;
    }
    
    .dataTables_wrapper .dataTables_paginate .page-item .page-link {
        padding: 3px 10px;
        font-size: 13px;
    }
    .dataTables_wrapper
    {
        margin-top: 10px;
    }
    .dataTables_wrapper .dataTables_info
    {
        font-size: 13px;
        padding-top: 0 !important;
        margin-top: 0;
    }
    .dataTables_info .paginate_button {
        margin: 0;
        padding: 3px 7px;
    }
    .tab_view .nav-pills .nav-item
    {
        margin-right: 10px;
    }
    .tab_view .nav-pills .nav-link
    {
        font-size: 13px;
    }
    .audit_details_view 
    {
        padding-top: 0;
        margin: 0 0 15px 0 !important;
    }
    .audit_details_view .col
    {
        padding-left: 0;
    }
    .permission_button_box
    {
        justify-content: flex-start;
        margin-bottom: 15px;
    }
    .cus_all_datatype .MuiGrid-item {
        min-width: 100%;
        padding-top: 5px !important;
    }
    
    .drpdown_tree_select.react-dropdown-tree-select
    {
        z-index: 990;
        position: relative;
    }
    
    .crp_upload_wrap .crp_upload_wrap_top .crm_obs
    {
        max-width: 200px;
    }
    
    .crp_upload_wrap_top .bordered_btn.ml-16 span
    {
        display: none;
    }
    .parameter_view_main
    {
        padding: 0;
        background: none;
    }
    .parameter_view .content_wrap_comman
    {
        padding: 0;
        max-height: 100%;
    }
    .sub_parameter_box .move {
        min-width: 15px;
    }
    .sub_parameter_box .move img
    {
        width: 11px;
    }
    .audit_seprator_bottom
    {
        margin: 0 !important;
    }
    .allView .react-dropdown-tree-select .dropdown
    {
        width: 100%;
    }
    .obs_single_user_modal_bulk .modal-dialog
    {
        max-width: 100%;
    }
    .drag_file_upload_area {
        padding: 30px 15px;
    }
    .card_legends_box .card_legends h4
    {
        font-size: 16px;
    }

}

@media only screen and (min-width:320px) and (max-width:575px) {
    .res_col_mb_10{
        margin-bottom: 10px;
    }
    .col_flex_wrap{
        flex-wrap: wrap;
    }
    .col_flex_wrap .page_title
    {
        margin-bottom: 10px;
    }
    .col_flex_wrap .form_style_comman
    {
        width: 100% !important;
    }
    #launcher-frame {
        right: 62px !important;
    }
    .page_title_box .page_title span.pe-3
    {
        padding-right: 6px !important;
    }
    .nav_sec .logo-main .logo-normal img
    {
        max-width: 117px;
    }
    .comman_btn
    {
        white-space: nowrap;
        padding: 7px 12px;
        font-weight: 500;
        margin-bottom: 7px;
        font-size: 13px;
    }
    .bordered_btn
    {
        padding: 7px 12px;
        margin-bottom: 7px;
        font-size: 13px !important;
    }
    .table_exp_area .exp_btn.btn
    {
        padding: 7px 12px;
        font-size: 13px;
        white-space: nowrap;
    }
    .btn.btn-primary
    {
        padding: 7px 12px;
        font-size: 13px;
        margin-bottom: 7px;
    }
    .file_upload_custom .btn {
        padding: 7px 12px;
        font-size: 13px;
    }
    .comman_btn.me-3,
    .bordered_btn.me-3{
        margin-right: 5px !important;
    }
    .comman_btn.btn:disabled, .comman_btn.btn.disabled, .bordered_btn.btn-primary:disabled
    {
        padding: 7px 12px;
        font-size: 13px;
    }
    .page_title
    {
        margin-right: 10px;
    }
    .blank_lbl
    {
        display: none;
    }
    .table_style_comman .table td .form-control
    {
        min-width: 130px;
    }
    .crp_upload_wrap .crp_upload_wrap_top
    {
        flex-wrap: wrap;
    }
    .crp_upload_wrap .crp_upload_wrap_top .crm_obs
    {
        max-width: 100%;
        margin: 0 0 15px 0;
    }
    
    .crp_upload_wrap_top .bordered_btn.ml-16
    {
        margin-left: 9px;
        padding: 8px 12px;
    }
   
    .crp_upload_wrap .progress-wrap {
        max-width: 100%;
    }
    .topMenuImport
    {
        display: initial;
    }
    .false_div
    {
        display: none;
    }
    .parameter_card .parameter_card_head, 
    .parameter_card .parameter_card_body {
        padding: 15px 10px;
    }
    .para_comman p
    {
        font-size: 12px;
    }
    .sub_parameter_box
    {
        padding: 7px;
        margin-left: 18px;
    }
    .form_style_comman .form-select
    {
        width: 100%;
    }
    .countdiv {
        grid-template-columns: repeat(1, 1fr) !important;
        gap: 15px !important;
    }
    .custom_fileupload .file-upload-wrapper:before
    {
        padding: 12px 12px;
        font-size: 13px;
        font-weight: 500;
    }
    .form_wizard_step ul li
    {
        min-width: auto;
    }
}

@media only screen and (min-width:576px) and (max-width:767px) {
    .countdiv {
        grid-template-columns: repeat(2, 2fr) !important;
        gap: 15px !important;
    }
    .form_wizard_step ul li
    {
        min-width: 140px;
    }
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .permission_button_box .btn {
        margin: 3px 7px;
    }
    .calendarWrap .rdrDefinedRangesWrapper
    {
        width: 130px;
    }
    .form_wizard_step ul li
    {
        min-width: 160px;
    }
}

/********************@media only screen and (min-width:1024px) and (max-width:1200px)********************/


@media (min-width:992px) and (max-width:1199px) {}